import type { UserContractInformations } from '~/repository/entities/user'

export const useUserContracts = () => {
  const { $api } = useNuxtApp()
  const { signatoryPropertyIds } = useUserHouses()

  const userContractsState = useState<{
    contracts: UserContractInformations[]
  }>('state-user-contracts', () => ({
    contracts: [],
  }))

  const contracts = computed(() => userContractsState.value.contracts)
  const unapprovedContractInformation = computed(() =>
    contracts.value.filter(
      (contract) =>
        !contract.ownerApprovedAt &&
        !contract.lcApprovedAt &&
        signatoryPropertyIds.value.includes(Number(contract.house)),
    ),
  )

  const fetchContracts = async () => {
    const contracts = await $api.v2.userContractInformations.read()

    userContractsState.value.contracts = contracts?.data?.length
      ? contracts.data
      : []
  }

  return { fetchContracts, unapprovedContractInformation }
}
